// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acceptcookies {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100000;
  width: calc(100% - 20px);
  max-width: 500px;
  background-color: #fff;
  padding-inline: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkboxdivaccept {
  margin-top: 40px;
  display: flex;
  text-align: left;
  align-items: start;
}
.checkboxdivaccept input[type="checkbox"] {
  margin: 10px 10px 10px 0; /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
  transform: scale(1.5);
}
.acceptcookies img {
  height: 20px;
}

.acceptbutton {
  border-radius: 12px;
  background-color: #db291c;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/acceptcookies.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,wBAAwB,EACG,OAAO,EACN,OAAO,EACJ,sBAAsB,EAC3B,UAAU;EACpC,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".acceptcookies {\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  z-index: 100000;\n  width: calc(100% - 20px);\n  max-width: 500px;\n  background-color: #fff;\n  padding-inline: 20px;\n  border-radius: 12px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.checkboxdivaccept {\n  margin-top: 40px;\n  display: flex;\n  text-align: left;\n  align-items: start;\n}\n.checkboxdivaccept input[type=\"checkbox\"] {\n  margin: 10px 10px 10px 0;\n  -ms-transform: scale(1.5); /* IE */\n  -moz-transform: scale(1.5); /* FF */\n  -webkit-transform: scale(1.5); /* Safari and Chrome */\n  -o-transform: scale(1.5); /* Opera */\n  transform: scale(1.5);\n}\n.acceptcookies img {\n  height: 20px;\n}\n\n.acceptbutton {\n  border-radius: 12px;\n  background-color: #db291c;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  margin-top: 20px;\n  margin-left: 10px;\n  margin-right: auto;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
