// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataprivacy,
.dataprivacybody {
  text-align: left;
  max-width: 1400px;
  padding: 10px 30px;
  margin: 0px auto;
}
.dataprivacy h1 {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.dataprivacy p {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;

  margin-top: 30px;
  color: #db291c;
}
.dataprivacybody ul {
  list-style: disc;
  margin-top: 0px;
  margin-left: 50px;
  margin-bottom: 10px;
}
.dataprivacybody h2 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #db291c;
}
.dataprivacybody h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dataprivacybody h4 {
  margin-bottom: 5px;
}
.dataprivacybody p {
  margin-bottom: 10px;
}
.dataprivacybody {
  margin-bottom: 40px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/dataprivacy.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;;EAEhB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".dataprivacy,\n.dataprivacybody {\n  text-align: left;\n  max-width: 1400px;\n  padding: 10px 30px;\n  margin: 0px auto;\n}\n.dataprivacy h1 {\n  font-size: 30px;\n  margin-bottom: 20px;\n  margin-top: 20px;\n}\n.dataprivacy p {\n  margin-bottom: 10px;\n  font-size: 20px;\n  font-weight: 500;\n\n  margin-top: 30px;\n  color: #db291c;\n}\n.dataprivacybody ul {\n  list-style: disc;\n  margin-top: 0px;\n  margin-left: 50px;\n  margin-bottom: 10px;\n}\n.dataprivacybody h2 {\n  font-size: 22px;\n  margin-bottom: 20px;\n  margin-top: 20px;\n  color: #db291c;\n}\n.dataprivacybody h3 {\n  font-size: 18px;\n  font-weight: 500;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.dataprivacybody h4 {\n  margin-bottom: 5px;\n}\n.dataprivacybody p {\n  margin-bottom: 10px;\n}\n.dataprivacybody {\n  margin-bottom: 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
