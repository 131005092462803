// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: #ffffff;
  padding: 0px;
  border-radius: 5px;
  max-height: 90%;
  overflow-y: auto;
  max-width: 90%;
}

.closeicon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.closeiconcontainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.closeiconcontainer img {
  height: 20px;
  width: 20px;
}
.popup-content .contactusmainbody {
  box-shadow: none;
  margin-top: 0px;
}

.testimonial-popup {
  width: 600px;
  max-width: 80%;
  max-height: calc(100vh - 100px);
}
.testimonial-popup p {
  max-height: calc(100vh - 314px);
  overflow-y: auto;
}
.popup-content .contactusbody {
  background-color: #ffffff;
  padding: 10px 0px;
}
@media screen and (max-width: 1000px) {
  .testimonial-popup {
    padding: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/contactformpopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,+BAA+B;AACjC;AACA;EACE,+BAA+B;EAC/B,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.popup-content {\n  position: relative;\n  background: #ffffff;\n  padding: 0px;\n  border-radius: 5px;\n  max-height: 90%;\n  overflow-y: auto;\n  max-width: 90%;\n}\n\n.closeicon {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n.closeiconcontainer {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 5px;\n}\n.closeiconcontainer img {\n  height: 20px;\n  width: 20px;\n}\n.popup-content .contactusmainbody {\n  box-shadow: none;\n  margin-top: 0px;\n}\n\n.testimonial-popup {\n  width: 600px;\n  max-width: 80%;\n  max-height: calc(100vh - 100px);\n}\n.testimonial-popup p {\n  max-height: calc(100vh - 314px);\n  overflow-y: auto;\n}\n.popup-content .contactusbody {\n  background-color: #ffffff;\n  padding: 10px 0px;\n}\n@media screen and (max-width: 1000px) {\n  .testimonial-popup {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
