// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsmainbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  color: #212121;
}
.newscard {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  min-width: calc(32% - 10px);
}
.newscard img {
  width: 100%;
  object-fit: cover;
}
.newscard p {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
}
.newscard hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0.5px solid #dedede;
}
.newscard h3 {
  font-size: 20px;
  text-align: left;
}
.newsbody {
  gap: 30px;
  max-width: 1000px;
  justify-content: left;
  display: flex;
  margin: 40px auto;
  flex-wrap: wrap;
}
.newsmainbody h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
  .newscard {
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 650px) {
  .newscard {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/news-updates.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,SAAS;EACT,iBAAiB;EACjB,qBAAqB;EACrB,aAAa;EACb,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".newsmainbody {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  padding: 40px;\n  background-color: #ffffff;\n  color: #212121;\n}\n.newscard {\n  display: flex;\n  flex-direction: column;\n  max-width: 280px;\n  width: 100%;\n  min-width: calc(32% - 10px);\n}\n.newscard img {\n  width: 100%;\n  object-fit: cover;\n}\n.newscard p {\n  margin-top: 10px;\n  text-align: left;\n  font-size: 14px;\n}\n.newscard hr {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  border: 0.5px solid #dedede;\n}\n.newscard h3 {\n  font-size: 20px;\n  text-align: left;\n}\n.newsbody {\n  gap: 30px;\n  max-width: 1000px;\n  justify-content: left;\n  display: flex;\n  margin: 40px auto;\n  flex-wrap: wrap;\n}\n.newsmainbody h1 {\n  font-size: 40px;\n  margin-bottom: 10px;\n}\n\n@media screen and (max-width: 1000px) {\n  .newscard {\n    max-width: calc(50% - 20px);\n  }\n}\n\n@media screen and (max-width: 650px) {\n  .newscard {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
