// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.talktousmain {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.talktousmain h3 {
  font-size: 32px;
  margin-bottom: 20px;
}
.talktousmain p {
  max-width: 1000px;
  margin-bottom: 20px;
}
.talktousmain button {
  width: 200px;
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/talktous.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".talktousmain {\n  background-color: #f2f2f2;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  text-align: center;\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n.talktousmain h3 {\n  font-size: 32px;\n  margin-bottom: 20px;\n}\n.talktousmain p {\n  max-width: 1000px;\n  margin-bottom: 20px;\n}\n.talktousmain button {\n  width: 200px;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
