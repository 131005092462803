// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeindustries {
  padding: 40px;
  background-color: #f1f1f1;
}
.industriesrow {
  display: flex;
  margin: 30px auto 10px;
  max-width: 1400px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.industrycard {
  padding: 20px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  align-items: center;
  transition: all 0.3s ease;
}
.industrycard:hover {
  transform: scale(1.1);
}
.industrycardheader {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dc291c;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.industrycardcontent p {
  font-size: 14px;
  margin-top: 10px;
}
.industrycardheader img {
  height: 40px;
}

@media screen and (max-width: 1000px) {
  .industriesrow {
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
  }
  .industrycard {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .industriesrow {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/industry.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,uCAAuC;AACzC;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,SAAS;IACT,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".homeindustries {\n  padding: 40px;\n  background-color: #f1f1f1;\n}\n.industriesrow {\n  display: flex;\n  margin: 30px auto 10px;\n  max-width: 1400px;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n.industrycard {\n  padding: 20px;\n  max-width: 300px;\n  display: flex;\n  flex-direction: column;\n  border-radius: 20px;\n  align-items: center;\n  transition: all 0.3s ease;\n}\n.industrycard:hover {\n  transform: scale(1.1);\n}\n.industrycardheader {\n  width: 60px;\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #dc291c;\n  border-radius: 50%;\n  margin-bottom: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n}\n.industrycardcontent p {\n  font-size: 14px;\n  margin-top: 10px;\n}\n.industrycardheader img {\n  height: 40px;\n}\n\n@media screen and (max-width: 1000px) {\n  .industriesrow {\n    gap: 30px;\n    flex-wrap: wrap;\n    align-items: center;\n  }\n  .industrycard {\n    margin-bottom: 20px;\n  }\n}\n@media screen and (max-width: 600px) {\n  .industriesrow {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
