import React, { useState } from "react";
import "../styles/immigrationpage.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import immigrationImage from "../images/immigrationpages.jpeg";
import { useNavigate } from "react-router-dom";
import ContactForm from "../components/contactform";
export const CriticalSkillsOne = () => {
  return (
    <div className="criticalskillsbodyelement">
      <h3>Introduction</h3>
      <p>
        A Short Stay ‘C’ Visit (Tourist) Visa permits you to travel to Ireland
        for up to 90 days for purposes such as:
      </p>
      <ul>
        <li>Tourism</li>
        <li>Visiting family or friends</li>
        <li>
          Studying, such as attending a short-term English language course
        </li>
        <li>Getting married</li>
        <li>Receiving medical treatment</li>
      </ul>
      <p>This visa does not permit you to:</p>
      <ul>
        <li>Engage in any form of work (paid or unpaid)</li>
        <li>Access publicly funded services, such as public hospitals</li>
      </ul>
    </div>
  );
};

export const CriticalSkillsTwo = () => {
  return (
    <div className="criticalskillsbodyelement">
      <h3>How to Apply</h3>
      <p>
        Complete the online application form accurately and honestly. After
        finishing the online application, follow the instructions provided on
        the Application Form generated by the system. This form will include
        details on where to submit your supporting documents.
      </p>
      <p>
        You must print, sign, and date the Application Form, then submit it
        along with your supporting documents. You might also be required to
        provide biometric information as part of the process. Refer to the guide
        below for details on supporting documentation.
      </p>
      <p>
        <strong>Important:</strong> Do not include false or misleading
        information or documents in your application. Doing so may result in a
        refusal and could potentially bar you from obtaining an Irish visa for
        up to five years.
      </p>
    </div>
  );
};

export const CriticalSkillsThree = () => {
  return (
    <div className="criticalskillsbodyelement">
      <h3>Documents to Send for a Short-Stay Visa</h3>
      <p>
        You must provide documents to support your application. The key
        documents required are detailed below. For additional information, visit
        the Irish Immigration Delivery website. Ensure all documents are
        originals (not photocopies). Documents not in English must be
        accompanied by a certified translation and the original document.
      </p>
      <ul>
        <li>
          <strong>Birth, Marriage, and Death Certificates:</strong> These will
          be returned to you. If you want other documents returned, include a
          note with your application requesting their return and send a
          photocopy along with the original of the document you wish to have
          returned.
        </li>
        <li>
          <strong>Travel Insurance:</strong> If your visa is approved, you must
          obtain travel insurance before traveling to Ireland.
        </li>
      </ul>
    </div>
  );
};
export default function ShortTermVisa() {
  const [selectedMode, setSelectedMode] = useState(1);
  const navigate = useNavigate();
  return (
    <div className="criticalskillsmain">
      <Navbar />
      <div className="workpageheader immigrationpageheader">
        <div className="workheaderleft">
          <p
            onClick={() => {
              navigate("/immigration");
            }}
          >
            <u>
              <ion-icon name="arrow-back-outline"></ion-icon>
              Back to Immigration
            </u>
          </p>
          <h1>Short Term Visa</h1>
          <p>
            If you plan to visit Ireland for a holiday or a short break of less
            than 90 days, you will need a Short Stay ‘C’ Tourist Visa if you are
            from a visa-required country. Below is the information you need
            regarding the conditions and application process for this type of
            visa.
          </p>
          <button
            className="contactusbutton"
            onClick={() => navigate("/contact-us")}
          >
            Know More
          </button>
        </div>
        <div className="workheaderright">
          <img src={immigrationImage} alt="Immigration" />
        </div>
      </div>
      <div className="criticalskillbody">
        <div className="workpagecomponentselector">
          <ul>
            <li
              onClick={() => setSelectedMode(1)}
              className={selectedMode === 1 ? "selectedelement" : ""}
            >
              Introduction
            </li>
            <li
              onClick={() => setSelectedMode(2)}
              className={selectedMode === 2 ? "selectedelement" : ""}
            >
              How to Apply
            </li>
            <li
              onClick={() => setSelectedMode(3)}
              className={selectedMode === 3 ? "selectedelement" : ""}
            >
              Documents to Send
            </li>
          </ul>
        </div>
      </div>
      <div className="criticalpagecontent">
        {selectedMode === 1 && <CriticalSkillsOne />}
        {selectedMode === 2 && <CriticalSkillsTwo />}
        {selectedMode === 3 && <CriticalSkillsThree />}
      </div>
      <ContactForm />
      <Footer />
    </div>
  );
}
