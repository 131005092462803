// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stampheader {
  display: flex;
  align-items: center;
  background-color: #ffcaae;
  padding: 20px 40px;
  justify-content: space-between;
  text-align: left;
}

.workheaderleft h5 {
  margin: 0;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  text-decoration: underline;
  color: #333;
  cursor: pointer;
}
.stampbody {
  padding: 40px 40px;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  color: #6c6c6c;
}
.stampbody h2 {
  font-size: 28px;
  color: #3b3b3b;
  margin-bottom: 10px;
}
.stampbody p {
  font-size: 16px;
}
.stampbody hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}
.stampbody b {
  font-weight: 600;
}
.stampbody ul {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.stampbody ul li {
  margin-left: 30px;
}
@media screen and (max-width: 1000px) {
  .stampheader {
    padding: 20px 20px;
    flex-direction: column;
  }

  .workheaderleft h5 {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  .stampbody h2 {
    font-size: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/stamp1g.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;EAC1B,WAAW;EACX,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,cAAc;AAChB;AACA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,SAAS;EACT,0BAA0B;AAC5B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".stampheader {\n  display: flex;\n  align-items: center;\n  background-color: #ffcaae;\n  padding: 20px 40px;\n  justify-content: space-between;\n  text-align: left;\n}\n\n.workheaderleft h5 {\n  margin: 0;\n  font-size: 16px;\n  margin-bottom: 10px;\n  font-weight: 500;\n  text-decoration: underline;\n  color: #333;\n  cursor: pointer;\n}\n.stampbody {\n  padding: 40px 40px;\n  text-align: left;\n  max-width: 1200px;\n  margin: 0 auto;\n  color: #6c6c6c;\n}\n.stampbody h2 {\n  font-size: 28px;\n  color: #3b3b3b;\n  margin-bottom: 10px;\n}\n.stampbody p {\n  font-size: 16px;\n}\n.stampbody hr {\n  margin: 20px 0;\n  border: 0;\n  border-top: 1px solid #ccc;\n}\n.stampbody b {\n  font-weight: 600;\n}\n.stampbody ul {\n  list-style-type: disc;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.stampbody ul li {\n  margin-left: 30px;\n}\n@media screen and (max-width: 1000px) {\n  .stampheader {\n    padding: 20px 20px;\n    flex-direction: column;\n  }\n\n  .workheaderleft h5 {\n    font-size: 14px;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  .stampbody h2 {\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
